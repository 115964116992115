import http from './http'

import Vue from 'vue'
import axios from 'axios'
// import qs from 'qs'

Vue.prototype.$axios = axios

axios.defaults.baseURL = 'https://apis.qikevip.com/api'

//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */
let resquest = "https://apis.qikevip.com/api";
let resquest_new = "https://manage.qikevip.com/api";

// get请求

//加盟商标
export function getClients(params) {
    return http.get(`${resquest}/pc/Clients`, { params })
}
// banner轮播图
export function getBanners(params) {
    return http.get(`${resquest}/pc/Banners`, { params })
}
////新闻
export function getNews(params) {
    return http.get(`${resquest}/pc/News`, { params })
}
// 新闻详情
export function getNewDetails(params) {
    return http.get(`${resquest}/pc/NewDetails/` + params)
}
///公司成员
export function getTeams(params) {
    return http.get(`${resquest}/pc/Teams`, { params })
}
// 员工详情
export function getTeamsDetails(params) {
    return http.get(`${resquest}/pc/TeamDetails/` + params)
}
// /招聘
export function getRecruitment(params) {
    return http.get(`${resquest}/pc/RecruitmentPositions/`, { params })
}
// ///招聘详情
export function getRecruitmentDetails(params) {
    return http.get(`${resquest}/pc/RecruitmentDetails/` + params)
}
///师资力量
export function getAuthors(params) {
    return http.get(`${resquest}/pc/Authors`, { params })
}
///公司详细情况
//地址电话等
export function getBranchOffices(params) {
    return http.get(`${resquest}/pc/BranchOffices`, { params })
}
//课程研发
export function getCourseLists(params) {
    return http.get(`${resquest}/pc/CourseLists`, { params })
}
///公司环境
export function getCompanyStyles(params) {
    return http.get(`${resquest_new}/office`, { params })
}
//员工活动
export function getStaffActivity(params) {
    return http.get(`${resquest}/pc/staffActivity`, { params })
}
//宣传视频
export function getVideoList(params) {
    return http.get(`${resquest_new}/video_publicity`,params)
}
// 发展历程
export function getfazhanlist(params) {
    return http.get(`${resquest_new}/developing_course`,params)
}




// post
// 企业入驻
export function EnterCompany() {
    return `${resquest}/pc/EnterCompany`
}
// 导师入驻
export function EnterTeacher() {
    return `${resquest}/pc/EnterTeacher`
}
// 城市加盟
export function ManageApply() {
    return `${resquest}/pc/manage-apply`
}
//留言反馈
export function Feedback() {
    return `${resquest}/pc/Feedback`
}
