import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)
const routes = [{
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        component: () =>
            import ('@/views/home/index.vue'),
        meta: {
            keepAlive: true
        }
    },

    {
        path: '/my',
        name: 'my',
        component: () =>
            import ('@/views/my/index.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/news',
        name: 'news',
        component: () =>
            import ('@/views/news/index.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/partner',
        name: 'partner',
        component: () =>
            import ('@/views/partner/index.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/teacher',
        name: 'teacher',
        component: () =>
            import ('@/views/teacher/index.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/productcenter',
        name: 'productcenter',
        component: () =>
            import ('@/views/productcenter/index.vue'),
        meta: {
            keepAlive: true
        }
    },
    // 招聘详情
    {
        path: '/joinusdetail',
        name: 'Joinusdetail',
        component: () =>
            import ('@/views/my/joinusdetails.vue'),
        meta: {
            hidden: true,
            keepAlive: true
        }
    },
    // 员工详情
    {
        path: '/groupdetail/:id',
        name: 'groupdetail',
        component: () =>
            import ('@/views/my/groupdetail.vue'),
        meta: {
            hidden: true,
            keepAlive: true

        }
    },
    //新闻详情
    {
        path: '/newdetaile/:id',
        name: 'newdetaile',
        component: () =>
            import ('@/views/news/newdetaile.vue'),
        meta: {
            hidden: true,
            keepAlive: true
        }
    },
    {
        path: '**',
        component: () =>
            import ('@/views/home/index.vue')
    }

]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router