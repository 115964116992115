<template>
  <div class="container">
    <div class="box">
        <router-view ></router-view>
      <footer class="footer">
        <Footer />
      </footer>
    </div>
  </div>
</template>

<script>
import Footer from "./components/footer.vue";
export default {
  components: {
    Footer,
  },
};
</script>

<style lang="stylus">
*
  padding 0
  margin 0
  list-style none

html, body, .container
  width 100%
  background-color #fff

html
  font-size 26.6666666vw

@media all and (min-width: 750px )
  html
    font-size 100px

body
  font-size 12px

.container
  display flex
  flex-direction column
  max-width 1080px
  margin 0 auto
  .box
    width 100%
    // flex 1
    // display flex
    // flex-direction column
    .header
      width 100%
      // height 0.5rem
    .content
      width 100%
      // flex 1
      overflow hidden
    .footer
      width 100%
</style>
