<template>
	<div style="clear: both">
		<div class="banner">
			<div class="cloud">
				<div class="cloud-yun">
					<h2 style="color: #fff">联系我们</h2>
					<img src="../assets/img/homeimg/ic_line.png" alt="" />
				</div>
			</div>
			<div>
				<van-tabs v-model="active" animated background="rgba(0,0,0,0)" title-inactive-color="#ACADAF"
					title-active-color="#F85346" style="height: 180px">
					<van-tab :title="item.company_name" v-for="item in gsCont" :key="item.id">
						<div style="margin-top: 15px">
							<div class="word">
								地址：{{item.address}}
							</div>
							<div class="word">邮编：{{item.post_code}}</div>
							<div class="word">电话：{{item.tel}}</div>
							<div class="word">邮箱：{{item.email}}</div>
						</div>
					</van-tab>
				</van-tabs>
				<div class="banner-img">
					<img src="../assets/img/homeimg/img_android.png" alt="" />
					<img src="../assets/img/homeimg/img_ios.png" alt="" />
				</div>
				<div class="xian"></div>
				<div class="text">
					© 2020企课网 版权所有 京ICP备17059533号-1
					<br />助力千万家企业建立实效企业学习云平台培养优秀人才
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from "vue";
	import {
		Tab,
		Tabs
	} from "vant";
	import {
		getBranchOffices
	} from '../api/api.js'
	Vue.use(Tab);
	Vue.use(Tabs);
	export default {
		data() {
			return {
				active: 1,
				gsCont: []
			};
		},
		created() {
			this.gsData()
		},
		methods: {
			gsData() {
				//    this.$axios.get('/pc/BranchOffices', {
				//      params: {
				//        page: 1,
				// per_page: 20
				//      }
				//    })
				var obj = {
					page: 1,
					per_page: 20
				};
				getBranchOffices(obj).then((res) => {
					// console.log(res)
					if (res.data.code === 1000) {
						this.gsCont = res.data.data.lists
						// console.log(this.gsCont)
					} else {
						// this.loading = true
					}
				}).catch((err) => {
					console.log(err)
				})
			}
		}
	};
</script>
<style lang="stylus" scoped>
	.banner {
		background: url('../assets/img/homeimg/bg_four.png') no-repeat;
		background-size: 100% 100%;
	}

	.cloud {
		display: flex;
		justify-content: center;
		text-align: center;
		line-height: 0.2rem;
		padding-bottom: 0.2rem;

		.cloud-yun {
			margin-top: 0.3rem;

			img {
				width: 1.46rem;
			}

			h2 {
				font-size: 0.19rem;
				font-family: PingFang SC;
				font-weight: 600;
				color: #212121;
				line-height: 0.26rem;
			}
		}
	}

	.word {
		text-align: center;
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 23px;
		opacity: 0.8;
		width: 3rem;
		margin: auto;
	}

	.banner-img {
		display: flex;
		justify-content: space-evenly;
		margin-top: 0.35rem;

		img {
			width: 0.9rem;
			height: 1.4rem;
			border-radius: 4px;
		}
	}

	.xian {
		width: 100%;
		height: 1px;
		border-bottom: 1px solid #4B4B54;
		margin-top: 20px;
	}

	.text {
		text-align: center;
		width: 3.545rem;
		font-size: 7px;
		font-family: PingFang;
		font-weight: 400;
		color: #CCCCCC;
		opacity: 0.6;
		margin: 0.2rem auto 0;
		padding-bottom: 0.1rem;
	}
</style>
